import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import { Flex, Text, Box, Heading, useTheme } from "@chakra-ui/core"
import { SectionWrapper, Section } from "../design-system/layout"
import Layout from "../layouts/layout"
import { htmlSerializer } from "../prismic"
import SEO, { parseMetadataFromPrismic } from "../layouts/seo"
import { Global, css } from "@emotion/core"

const Page = ({
  layoutStyles,
  page,
  meta,
  nav,
  node,
  siteMetadata,
  background,
}) => {
  const { colors } = useTheme()
  return (
    <Layout
      nav={nav}
      siteTitle={page.page_title}
      host={siteMetadata.host}
      seoData={parseMetadataFromPrismic(node, "en-gb")}
    >
      <SectionWrapper mt={{ base: "98px", sm: "112px" }} bg={"gray.100"}>
        <Section>
          <Heading
            as="h1"
            fontSize={{ base: "3xl", sm: "4xl", md: "5xl" }}
            fontWeight="normal"
            color="gray.800"
            mt={12}
            mb={12}
            mx={3}
            dangerouslySetInnerHTML={{ __html: page.page_title }}
          />
        </Section>
      </SectionWrapper>
      <SectionWrapper>
        <Section
          direction="column"
          my={8}
          css={css`
            p {
              margin: 0.75rem 0;
            }

            a {
              color: ${colors.blue[500]};
            }

            ul {
              list-style-position: inside;
              li {
                margin: 0.25rem 0;
              }
            }

            h3 {
              margin-top: 24px;
              font-size: 24px;
            }

            h4 {
              margin-top: 24px;
              font-size: 20px;
            }

            table {
              border-collapse: collapse;
              border-spacing: 0;
              width: 100%;
              margin: 20px 0;

              vertical-align: middle !important;

              thead {
                display: table-header-group;
                vertical-align: middle;
                border-color: inherit;
              }
              tbody {
                display: table-row-group;
                vertical-align: middle;
                border-color: inherit;

                tr {
                  border-top: 1px solid #e5e5e5;
                }
              }

              tr {
                display: table-row;
                vertical-align: inherit;
                border-color: inherit;
              }

              td {
                color: ${colors.gray[700]};
                padding: 16px 12px;
              }

              th {
                white-space: nowrap;
                padding: 16px 12px;
                text-align: left;
                vertical-align: bottom;
                font-size: 0.875rem;
                font-weight: 400;
                color: ${colors.gray[600]};
                text-transform: uppercase;
              }
            }
          `}
        >
          <Box mx={3}>
            <RichText render={page.page_body} htmlSerializer={htmlSerializer} />
          </Box>

          {page.body &&
            page.body.map(section => {
              if (section.type === "text") {
                return (
                  <Box mx={3}>
                    <RichText
                      render={section.primary.page_content}
                      htmlSerializer={htmlSerializer}
                    />
                  </Box>
                )
              }

              if (section.type === "table") {
                return (
                  <Box mx={3} maxWidth="100%" overflowX="scroll">
                    <table
                      border
                      className="uk-table uk-table-middle uk-table-divider"
                    >
                      <thead>
                        <tr>
                          {section.primary.table_headingColumn_1 && (
                            <th
                              style={{ whiteSpace: "nowrap" }}
                              className="uk-table-shrink"
                            >
                              {section.primary.table_headingColumn_1}
                            </th>
                          )}
                          {section.primary.table_headingColumn_2 && (
                            <th>{section.primary.table_headingColumn_2}</th>
                          )}
                          {section.primary.table_headingColumn_3 && (
                            <th>{section.primary.table_headingColumn_3}</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {section.fields &&
                          section.fields.map(row => (
                            <tr>
                              {row.table_row_column_1 && (
                                <td>
                                  <RichText
                                    render={row.table_row_column_1}
                                    htmlSerializer={htmlSerializer}
                                  />
                                </td>
                              )}
                              {row.table_row_column_2 && (
                                <td>
                                  <RichText
                                    render={row.table_row_column_2}
                                    htmlSerializer={htmlSerializer}
                                  />
                                </td>
                              )}
                              {row.table_row_column_3 && (
                                <td>
                                  <RichText
                                    render={row.table_row_column_3}
                                    htmlSerializer={htmlSerializer}
                                  />
                                </td>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Box>
                )
              }
            })}
        </Section>
      </SectionWrapper>
    </Layout>
  )
}

export default ({ data }) => {
  const pageContent = data.prismic.allPages.edges[0].node
  const meta = data.site.siteMetadata

  const doc = data.prismic.allHomepages.edges.slice(0, 1).pop()
  const nav = doc.node.header_navigation.map(
    ({
      header_navigation__label,
      header_navigation__section,
      header_navigation__type_button,
      header_navigation_link,
    }) => ({
      label: header_navigation__label,
      section: header_navigation__section,
      isButton: header_navigation__type_button,
      link: header_navigation_link,
    })
  )

  return (
    <Page
      page={pageContent}
      meta={meta}
      nav={nav}
      node={doc.node}
      siteMetadata={data.site.siteMetadata}
    />
  )
}

Page.propTypes = {
  page: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

export const query = graphql`
  query PageQuery($uid: String) {
    prismic {
      allPages(uid: $uid) {
        edges {
          node {
            page_body
            page_sub_title
            page_title
            _meta {
              uid
            }
            body {
              ... on PRISMIC_PageBodyText {
                type
                label
                primary {
                  page_content
                }
              }
              ... on PRISMIC_PageBodyTable {
                type
                label
                primary {
                  table_headingColumn_1
                  table_headingColumn_2
                  table_headingColumn_3
                }
                fields {
                  table_row_column_1
                  table_row_column_2
                  table_row_column_3
                }
              }
            }
          }
        }
      }
      allHomepages: allWebsite_homepages {
        edges {
          node {
            _meta {
              uid
              id
              type
              lang
            }
            meta_title
            meta_description
            display_name
            canonical_url
            card_description
            card_image
            card_imageSharp {
              childImageSharp {
                fixed(width: 1200, height: 630) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            card_image_square
            card_image_squareSharp {
              childImageSharp {
                fixed(width: 400, height: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            card_title
            header_navigation {
              header_navigation__label
              header_navigation__section
              header_navigation__type_button
              header_navigation_link {
                _linkType
                ... on PRISMIC__FileLink {
                  url
                }
                ... on PRISMIC__Document {
                  _meta {
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
        host
      }
    }
  }
`
